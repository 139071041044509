import React, { useState } from "react";

function App() {
  const [salary, setSalary] = useState<number>(0);
  const [inputValue, setInputValue] = useState("");
  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const resetInputField = () => {
    setSalary(0);
    setInputValue("");
  };
  return (
    <>
      <div
        className="flex justify-center items-center"
        style={{ height: "40vh" }}
      >
        <form className="w-full max-w-sm">
          <div className="flex items-center border-b border-teal-500 py-2">
            <input
              onChange={(event) => {
                handleUserInput(event);
                const temp = Number(event.target.value);

                if (temp > 1000000) {
                  const tax = (temp - 1200000) * 0.3 + 112500;
                  const tempSal = (temp - tax - (0.04 * (tax + 112500))) / 12 - 1800 - 650 ;
                  setSalary(tempSal *0.98759);
                  return;
                }
                setSalary(0);
                return;
              }}
              value={inputValue}
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="number"
              placeholder="Salary"
              aria-label="Full name"
            />

            <button
              className="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded"
              type="button"
              onClick={() => resetInputField()}
            >
              Clear
            </button>
          </div>
        </form>
      </div>
      <div className="flex justify-center items-center font-black text-2xl	">
        {salary !== 0 &&
          salary.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })}
      </div>
    </>
  );
}

export default App;
